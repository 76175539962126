@charset "utf-8";

/* SPADA UI Framework v.0.1 */

/* Fonts */
@font-face {
    font-family: 'poppinsbold';
    src: local('poppinsbold'), url(./fonts/poppins-bold-webfont.eot);
    src: local('poppinsbold'), url(./fonts/poppins-bold-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinsbold'), url(./fonts/poppins-bold-webfont.woff2) format('woff2'),
         local('poppinsbold'), url(./fonts/poppins-bold-webfont.woff) format('woff'),
         local('poppinsbold'), url(./fonts/poppins-bold-webfont.ttf) format('truetype'),
         local('poppinsbold'), url(./fonts/poppins-bold-webfont.svg#poppinsbold) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsextrabold';
    src: local('poppinsextrabold'), url(./fonts/poppins-extrabold-webfont.eot);
    src: local('poppinsextrabold'), url(./fonts/poppins-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinsextrabold'), url(./fonts/poppins-extrabold-webfont.woff2) format('woff2'),
         local('poppinsextrabold'), url(./fonts/poppins-extrabold-webfont.woff) format('woff'),
         local('poppinsextrabold'), url(./fonts/poppins-extrabold-webfont.ttf) format('truetype'),
         local('poppinsextrabold'), url(./fonts/poppins-extrabold-webfont.svg#poppinsextrabold) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinslight';
    src: local('poppinslight'), url(./fonts/poppins-light-webfont.eot);
    src: local('poppinslight'), url(./fonts/poppins-light-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinslight'), url(./fonts/poppins-light-webfont.woff2) format('woff2'),
         local('poppinslight'), url(./fonts/poppins-light-webfont.woff) format('woff'),
         local('poppinslight'), url(./fonts/poppins-light-webfont.ttf) format('truetype'),
         local('poppinslight'), url(./fonts/poppins-light-webfont.svg#poppinslight) format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinsmedium';
    src: local('poppinsmedium'), url(./fonts/poppins-medium-webfont.eot);
    src: local('poppinsmedium'), url(./fonts/poppins-medium-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinsmedium'), url(./fonts/poppins-medium-webfont.woff2) format('woff2'),
         local('poppinsmedium'), url(./fonts/poppins-medium-webfont.woff) format('woff'),
         local('poppinsmedium'), url(./fonts/poppins-medium-webfont.ttf) format('truetype'),
         local('poppinsmedium'), url(./fonts/poppins-medium-webfont.svg#poppinsmedium) format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinsregular';
    src: local('poppinsregular'), url(./fonts/poppins-regular-webfont.eot);
    src: local('poppinsregular'), url(./fonts/poppins-regular-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinsregular'), url(./fonts/poppins-regular-webfont.woff2) format('woff2'),
         local('poppinsregular'), url(./fonts/poppins-regular-webfont.woff) format('woff'),
         local('poppinsregular'), url(./fonts/poppins-regular-webfont.ttf) format('truetype'),
         local('poppinsregular'), url(./fonts/poppins-regular-webfont.svg#poppinsregular) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinssemibold';
    src: local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.eot);
    src: local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.woff2) format('woff2'),
         local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.woff) format('woff'),
         local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.ttf) format('truetype'),
         local('poppinssemibold'), url(./fonts/poppins-semibold-webfont.svg#poppinssemibold) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsthin';
    src: local('poppinsthin'), url(./fonts/poppins-thin-webfont.eot);
    src: local('poppinsthin'), url(./fonts/poppins-thin-webfont.eot?#iefix) format('embedded-opentype'),
         local('poppinsthin'), url(./fonts/poppins-thin-webfont.woff2) format('woff2'),
         local('poppinsthin'), url(./fonts/poppins-thin-webfont.woff) format('woff'),
         local('poppinsthin'), url(./fonts/poppins-thin-webfont.ttf) format('truetype'),
         local('poppinsthin'), url(./fonts/poppins-thin-webfont.svg#poppinsthin) format('svg');
    font-weight: normal;
    font-style: normal;

}

/* Presets */
.text-size-up {
	font-size: 1.2em; 
}
.text-size-down {
	font-size: 0.9em;
}
.text-bold {
	font-family: 'poppinssemibold';
}
.text-extrabold {
	font-family: 'poppinsbold';
}
.text-italic {
	font-style: italic;
}
.text-color-warning {
	color: #f59e0b;
}
.text-color-success {
	color: #388e3c;
}
.text-color-error {
	color: #ef4444;
}
.text-color-link {
	color: #039be6;
}
.capitalize {
	text-transform: capitalize;
}
.uppercase {
	text-transform: uppercase;
}
.lowercase {
	text-transform: lowercase;
}
.underlined {
	text-decoration: underline;
}
.align-left {
	text-align: left;
}
.align-center {
	text-align: center;
}
.align-right {
	text-align: right;
}
.wrap { 
	word-wrap: break-word;
	overflow-wrap: break-word;
}
.width-30 {
	width: 30%;
}
.width-50 {
	width: 50%;
}
.width-75 {
	width: 75%;
}
.full-width {
	width: 100% !important;
}
.full-height {
	min-height: 100vh !important;
}
.has-padding {
	padding: 20px !important;
}
.has-padding-top {
	padding-top: 20px !important;
}
.has-padding-right {
	padding-right: 20px !important;
}
.has-padding-bottom {
	padding-bottom: 20px !important;
}
.has-padding-left {
	padding-left: 20px !important;
}
.has-padding-horizontal {
	padding-left: 20px !important;
	padding-right: 20px !important;
}
.has-padding-vertical {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}
.no-padding {
	padding-top: 0px !important;
	padding-right: 0px !important;
	padding-bottom: 0px !important;
	padding-left: 0px !important;
}
.has-margin {
	margin: 30px !important;
}
.has-margin-top {
	margin-top: 20px !important;
}
.has-margin-right {
	margin-right: 20px !important;
}
.has-margin-bottom {
	margin-bottom: 20px !important;
}
.has-margin-left {
	margin-left: 20px !important;
}
.no-margin {
	margin-top: 0px !important;
	margin-right: 0px !important;
	margin-bottom: 0px !important;
	margin-left: 0px !important;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.clear {
	clear: both;
}
.group:after {
	content: "";
	display: table;
	clear: both;
}
.hidden {
	display: none !important;
}
.hidden-sj { /*hidden except for sinar jaya*/
 	 /*display: none !important; */
}
.hidden-for-blessing {
	/*display: none !important;*/
}
.pre-wrap {
	white-space: pre-wrap;
}
.center {
	margin: 0 auto;
}
.absolute-center {
	display: block;
	width: 80%;
	height: auto;
	margin: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.no-border {
	border-top: none !important;
	border-right: none !important;
	border-bottom: none !important;
	border-left: none !important;
}
.rounded {
	border-radius: 7px !important;
	-webkit-border-radius: 7px !important;
	overflow: hidden;
}
.rounded-small {
	border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
	overflow: hidden;
}
.rounded-medium {
	border-radius: 10px !important;
	-webkit-border-radius: 10px !important;
	overflow: hidden;
}
.rounded-large {
	border-radius: 15px !important;
	-webkit-border-radius: 15px !important;
	overflow: hidden;
}
.clickable {
	cursor: pointer;
}
.animate-blink {
	animation: blink 1.2s normal infinite linear;
}
.animate-border-blink {
	animation: border-blink 1.5s normal infinite linear;
}
.scrolling-text {
	white-space: nowrap;
	inline-size: max-content;
	/* Starting position */
	-moz-transform:translateX(100%);
	-webkit-transform:translateX(100%);	
	transform:translateX(100%);
	/* Apply animation to this element */	
	-moz-animation: marquee 10s linear infinite;
	-webkit-animation: marquee 10s linear infinite;
	animation: marquee 10s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes marquee {
	0%   { -moz-transform: translateX(100%); }
	100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes marquee {
	0%   { -webkit-transform: translateX(100%); }
	100% { -webkit-transform: translateX(-100%); }
}
@keyframes marquee {
	0%   { 
		-moz-transform: translateX(100%); /* Firefox bug fix */
		-webkit-transform: translateX(100%); /* Firefox bug fix */
		transform: translateX(100%);		
	}
	100% {
		-moz-transform: translateX(-100%); /* Firefox bug fix */
		-webkit-transform: translateX(-100%); /* Firefox bug fix */
		transform: translateX(-100%);
	}
}
.button-small {
	padding: 7px 10px !important;
}
.button-blue {
	color: #333;
	background: #fff;
	border-color: #039be6 !important;
	cursor: pointer;
}
.button-red {
	background: #fff;
	border-color: #ff4040 !important;
	cursor: pointer;
}
.button-yellow {
	background: #fff;
	border-color: #ffca58 !important;
	cursor: pointer;
}
.button-green {
	background: #fff;
	border-color: #0d9488 !important;
	cursor: pointer;
}
.button-delete {
	color: #fff !important;
	background: #ff4040 !important;
	border-color: #ff4040 !important;
	cursor: pointer;
}


/* Tools */
.sticky-header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;
}
.spacer {
	content: " ";
	height: 1px;
	width: 100%;
	background-color: #ccc;
	margin: 20px 0;
	display: block !important;
}
.display-box {
	background-color: #f3f4f8;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}
.display-box-list {
	background-color: #f3f4f8;
    border: 1px solid #ddd;
    border-left: 3px solid #373950;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
}

/* Global Style */
html {
	background-color: #fff;
}
body {
	font-family: 'poppinsregular';
}
a {
	color: #039be6;
	text-decoration: none;
}
a:hover {
	color: #ff4d38;
	text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 30px;
}
ul,
ol {
	padding: 0;
	margin: 30px 0;
}
ul li,
ol li {
	margin-bottom: 15px;
}
ul.not-list,
ol.not-list {
	list-style: none;
	padding: 0;
	margin: 0 !important;
}
p {
	margin-top: 0;
	margin-bottom: 20px;
}
p:last-of-type {
	margin-bottom: 0px;
}
img {
	max-width: 100%;
	vertical-align: middle;
}
table {
	width: 100%;
}

/* Form */
form,
.form-row {
	width: 100% !important;
}
.form-row:not(:last-of-type) {
	margin-bottom: 30px;
}
.form-row sup {
	color: #d20001;
}
.form-label {
	font-family: 'poppinssemibold';
	font-size: 12px;
	line-height: 18px;
	color: #0D9488;
}
.form-helper {
	font-size: 12px !important;
	line-height: 18px !important;
	font-style: italic;
	color: #999;
	margin-top: 5px;
}
input {
	color: #222;
	background-color: #fff;
	outline: none;
}
input[type=text],
input[type=password],
input[type=email],
input[type=number],
input[type=phone],
.date-input {
	width: 100%;
	color: #222;
	border: 1px solid #cacaca !important;
}
.select-input button {
	width: 100%;
	border: 1px solid #cacaca;
	border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
}
.select-input button input {
	border: none !important;
	padding-right: 0px;
	margin-bottom: 0px;
}
.select-input button > div {
	border-radius: 10px !important;
	-webkit-border-radius: 10px !important;
}
.select-input button > div > div:last-of-type {
	margin-left: 0px;
	margin-right: 10px;
}
.select-input button > div > div:last-of-type svg {
	width: 18px;
	height: 18px;
}
.select-input.inline-select  button {
	border: none !important;
	padding: 0px !important;
}
.select-input.inline-select button input {
	width: 45px !important;
	padding: 0px !important;
}
.select-input.inline-select button input,
.select-input.inline-select button > div {
	background: transparent !important;
}
.select-input.inline-select button > div > div:last-of-type {
	margin-right: 0px;
}
textarea {
	width: 100% !important;
	min-height: 100px !important;
	color: #222;
	background-color: #fff;
	border: 1px solid #cacaca !important;
	outline: none;
	padding: 8px 15px;
}
input.display-only,
textarea.display-only {
	background-color: #f3f4f8;
	border: none;
}
input:focus {
	outline: none;
}
input[type=submit],
input[type=button],
input[type=reset] {
	width: auto;
	color: #fff;
	background-color: #039BE6;
	border: 1px solid #039BE6;
	outline: none !important;
	display: inline-block;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	cursor: pointer;
}
.file-input button {
	padding: 3px !important;
}
.file-input span {
	font-size: 12px;
}
::placeholder {
  color: #999999;
}
.control-search input {
	border-radius: 10px;
}
.control-display button {
	border-radius: 10px;
}

/* App Style */
.main-wrapper {
	min-height: 100vh !important;
	position: relative;
	overflow-x: hidden;
}
.wrapper {
	position: relative;
	margin: auto;
}
.container {
	width: 100%;
	min-width: unset !important;
	max-width: 1100px !important;
	margin: 0 auto;
	position: relative;
}
#page-wrapper {
	min-height: 100vh !important;
}
.login-form {
	height: auto;
	min-height: unset !important;
}
#top-panel {
	width: 100%;
	color: #fff;
	background-color: #373950;
	padding: 12px 25px;
	position: relative;
	z-index: 8;
}
#logo img {
	width: 100px;
}
#header-navigation a {
	color: #fff !important;
	padding: 8px 15px;
	-webkit-border-radius: 9999px;
	border-radius: 9999px;
}
#header-navigation button {
	color: #fff;
	background-color: transparent;
	border: none;
	padding: 8px 15px;
}
#header-navigation a:hover,
#header-navigation button:hover {
	background-color: #039be6;
}
#search-panel {
	position: relative;
}
#search-panel input {
	padding: 7.5px 10px 7.5px 48px !important;
}
#account-panel img {
	width: 32px;
}
#left-panel {
	background-color: #fff;
	padding: 15px 0px;
}
#left-panel li span {
	font-family: 'poppinsmedium';
	font-size: 14px;
}
.page-heading {
	background: #fff;
	padding: 30px 20px 20px 20px;
	-webkit-border-radius: 30px 30px 7px 7px;
	border-radius: 30px 30px 7px 7px;
	-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
}
.page {
	padding: 20px 0;
}
.page-content {
	padding: 0px 30px;
}
.page-block {
	background: #fff;
	padding: 30px 15px;
	-webkit-border-radius: 15px 15px 0 0;
	border-radius: 15px 15px 0 0;
}
.blocks {
	margin-bottom: 35px;
}
.block-content {
	width: 100%;
	background: #fff;
	padding: 15px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
	-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
}
.block-content-border {
	width: 100%;
	background: #fff;
	border: 1px solid #999;
	padding: 15px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
}
.page-block-content {
	width: 100%;
	background: #fff;
	border: 1px solid #dcdcdc;
	padding: 15px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
}
.info {
	width: 100%;
	background: #fff;
	padding: 15px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
}
.info.announcement,
.info.warning {
	background: #ffd54f;
}
.info.success {
	color: #fff;
	background: #8bc34a;
}
.info.alert,
.info.error {
	color: #fff;
	background: #ef5350;
}
.block-heading {
	font-family: 'poppinssemibold';
	margin-bottom: 15px;
}
.block-row {
	width: 100%;
	border: 1px solid #999;
	padding: 15px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
}
.block-grid {
	padding: 20px;
}
.no-data {
	margin-bottom: 15px;
}
.mobile-cart {
	color: #fff;
	background-color: #0D9488;
	padding: 6px 15px;
}

/* Data Table */
.table-header {
	color: #fff;
	background: #373950;
	padding: 15px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}
.table-header span {
	font-size: 12px;
}
.page-footer {
	color: #222;
	background: #fff;
	padding: 10px 15px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}
.table-row {
	width: 100%;
    background: #f1f1f1;
    padding: 10px 15px;
    border-radius: 7px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 5%);
}
.table-row.highlighted {
	color: #fff;
	background: #373950;
}
.table-row.block-content {
	background: #fff;
	padding: 15px;
    border-radius: 7px;
}
.table-row a {
	color: #039be6;
}
.table-row.no-data {
	text-align: center;
}
.table-row-date {
	text-align: left;
}
.table-row-id {
	text-align: left;
}
.table-row-name {
	text-align: left;
}
.table-row-total {
	text-align: left;
}
.table-row-total {
	text-align: right;
}
.table-row-channel {
	text-align: center;
}
.table-row-quantity {
	text-align: center;
}
.table-row-product {
	text-align: left;
}
.table-row-price {
	text-align: right;
}
.table-row-type {
	text-align: center;
}
.table-row-unit {
	text-align: center;
}
.table-row-variant {
	text-align: center;
}
.table-row-status {
	text-align: center !important;
}
.table-row-more {
	text-align: right;
}
.table-row-button {
	text-align: center;
}
.more-options a,
.account-options a {
	color: #222;
}
.table-row-subtotal {
	text-align: right;
}
.table-row-extra-label,
.table-row-extra-value {
	text-align: right;
}
.data-status {
	width: fit-content;
	color: #fff;
	background-color: #ccc;
	padding: 3px 7px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.data-status.pending {
	background-color: #EF4444;
}
.data-status.processed, .data-status.prepared {
	background-color: #F59E0B;
}
.data-status.completed, .data-status.approved, .data-status.complete, .data-status.ready {
	background-color: #166534;
}
.data-status.delivered, .data-status.ready, .data-status.received {
	background-color: #22C55E;
}
.data-status.void {
	background-color: #333;
}
.timestamp {
	width: fit-content;
	color: #555;
}
.timestamp span {
	line-height: 1 !important;
}
.compact-table .table-header {
	padding: 5px 15px;
}
.compact-table .table-row {
	padding: 5px 15px;
}
.pagination li {
	margin-bottom: 0px;
}

/* Documents */
.invoice-header {
	margin-bottom: 20px;
}
.data-title {
	margin-bottom: 10px;
}
.tabs a {
	font-family: 'poppinssemibold';
	font-size: 12px;
	color: #999;
	background: #eee;
	border: 1px solid #ccc;
	padding: 7px 10px;
	-webkit-border-radius: 5px 5px 0 0;
	border-radius: 5px 5px 0 0;
}
.tabs a:active,
.tabs a.active {
	color: #333;
	background: #fff;
	border-bottom: 1px solid #ff6755;
}
.page-tabs {
	margin-bottom: 20px;
}
.page-tabs a {
	font-size: 14px;
	color: #999;
	background: #fff;
	padding: 12px;
	-webkit-border-radius: 7px 7px 0 0;
	border-radius: 7px 7px 0 0;
}
.page-tabs a:hover {
	color: #333;
}
.page-tabs a:active,
.page-tabs a.active {
	font-family: 'poppinssemibold';
	color: #333;
	background: #fff;
	border-bottom: 1px solid #ff6755;
}
.page-tags {
	margin-bottom: 10px;
}
.page-tags a {
	font-size: 14px;
	color: #777;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 10px 15px;
	margin-bottom: 10px;
	border-radius: 7px !important;
	-webkit-border-radius: 7px !important;
}
.page-tags a:hover {
	color: #333;
	border-color: #039be6;
}
.page-tags a:active,
.page-tags a.active {
	color: #333;
	background-color: #fff;
	border-color: #039be6;
}
.document-options {
	margin-bottom: 10px;
}
.document-options a,
.document-options button {
	color: #222;
	border: 1px solid transparent;
	padding: 3px 5px;
	border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
}
.document-options a:hover,
.document-options a:focus,
.document-options button:hover,
.document-options button:active {
	background-color: #e3f2fd;
}
.tags a {
	color: #333 !important;
	border: 1px solid #ccc;
	padding: 8px 15px;
	margin-bottom: 8px;
	border-radius: 50px !important;
	-webkit-border-radius: 50px !important;
}
.tags a:active, .tags .active {
	border-color: #ff6755;
}
.editable,
.editable span {
	text-decoration: underline;
}
.editable svg {
	fill: #039be6 !important;
    stroke: #039be6 !important;
}
.editable a:hover svg {
	fill: #ff4d38 !important;
	stroke: #ff4d38 !important;
}
.data-section:not(:first-of-type) {
	border-top: 1px dashed #e2e8f0;
	padding-top: 35px;
}
.data-section:not(:last-of-type) {
	margin-bottom: 20px;
}
.data-section-heading {
	font-family: 'poppinssemibold';
	margin-bottom: 15px;
}
.note {
	background-color: #f3f4f8;
	border-left: 3px solid #ff6755;
	padding: 15px;
	-webkit-border-radius: 0 5px 5px 0;
	border-radius: 0 5px 5px 0;
}
.note p {
	font-size: 14px !important;
	line-height: 21px !important;
}
.address {
	font-size: 15px !important;
	line-height: 1.3 !important;
}

/* Layer */
.layer-view {
	width: 55vw;
	padding-right: 18px;
	padding-bottom: 25px;
	border-radius: 12px !important;
	-webkit-border-radius: 12px !important;
	overflow: hidden;
	position: relative;
}
.layer-view.medium {
	width: 40vw;
}
.layer-view.small {
	width: 30vw;
}
.close-layer {
	background-color: #fff;
	position: absolute;
	top: 10px;
	right: 10px;
}
.layer-box-header {
	text-align: center;
	padding: 25px;
	padding-bottom: 20px;
}
.layer-box-header h4 {
	margin-bottom: 0px;
}
.layer-box-content {
	padding-left: 25px;
	padding-right: 7px;
	overflow-x: hidden;
	overflow-y: auto;
}
.layer-box-content.has-actions {
	padding-bottom: 50px;
}
.layer-actions {
	background-color: #fff;
	padding: 5px 20px;
	-webkit-box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.1);
	box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.1);
	position: fixed !important;
	bottom: 0;
	left: 0;
	z-index: 9;
}


/* Tweak */
.calendar div button {
	padding: 3px !important;
}
.side-navigation-panel *, .side-navigation-panel:after, .side-navigation-panel:before {
	border-color: #fff !important;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected,
.hover\:border-pink-500:hover {
	border-left-width: 2px !important;
	border-color: #ff6755 !important;
}
.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option:before {
	content: "\003E";
	margin-right: 5px;
}
.side-navigation-panel-select-inner-option {
	justify-content: start !important;
}
.recharts-wrapper {
	font-size: 12px !important;
}
.Toastify__toast-container {
	padding: 0 20px !important;
}
.Toastify__toast-container--top-center {
	top: 20% !important
}
.Toastify__toast--success {
	background: #00c781 !important;
}
.Toastify__toast--error {
	background: #ff5252 !important;
}

/* iPad Pro & Larger */
@media screen and (min-width: 1024px) {

}

@media screen and (min-width: 768px) {
	.sticky-header {
		max-width: 600px;
		margin-left: -300px;
		position: fixed;
		top: 0;
		left: 50%;
		z-index: 6;
	}
	#left-panel {
		width: 280px;
	}
	.block-title {
		font-size: 26px !important;
		margin-bottom: 20px;
	}
}

/* Mobile Phone */
@media screen and (max-width: 767px) {
	.has-padding {
		padding: 15px !important;
	}
	.display-box {
		margin-bottom: 15px;
	}
	.form-row:not(:last-of-type) {
		margin-bottom: 20px;
	}
	#top-panel {
		padding: 12px 20px;
	}
	#mobile-nav-header {
		background-color: #373950 !important;
		padding: 18.5px 25px;
		position: relative;
	}
	#mobile-nav-header .close-layer {
		background-color: transparent;
	}
	#mobile-nav-header #logo {
		position: absolute;
		left: 50%;
		margin-left: -50px;
	}
	.blocks {
		margin-bottom: 25px;
	}
	.block-title {
		font-size: 22px !important;
		margin-bottom: 20px;
	}
	.block-grid {
		padding: 10px 5px;
	}
	.page-content {
		padding: 0px 20px;
	}
	.page-content.has-overflow {
		padding: 0px 20px;
	}
	.has-overflow {
		max-width: unset !important;
		overflow-x: scroll;
		padding: 0px 0 15px 0;
	}
	.mobile-overflow-large .table-header,
	.mobile-overflow-large .table-row {
		width: 400% !important;
		max-width: unset !important;
		overflow: visible;
	}
	.mobile-overflow-medium .table-header,
	.mobile-overflow-medium .table-row {
		width: 300% !important;
		max-width: unset !important;
		overflow: visible;
	}
	.mobile-overflow-small .table-header,
	.mobile-overflow-small .table-row {
		width: 200% !important;
		max-width: unset !important;
		overflow: visible;
	}
	.data-table .table-header.mobile-fit,
	.data-table .table-row.mobile-fit {
		width: 100% !important;
	}
	.layer-view,
	.layer-view.small,
	.layer-view.medium {
		width: 100vw;
		border-radius: 0px !important;
		-webkit-border-radius: 0px !important;
		overflow-x: visible;
		overflow-y: scroll;
		position: relative;
	}
	.close-layer {
		right: 30px;
	}
	.layer-box-header {
		padding: 20px;
	}
	.layer-box-content {
	    padding-left: 20px;
	    padding-right: 20px;
	    padding-bottom: 20px;
	}
	.layer-actions {
		background-color: #fff;
		padding: 5px 20px;
		position:  unset !important;
	}
	.data-title {
		margin-bottom: 20px;
	}
}

/* Mobile Phone (Small) */
@media screen and (max-width: 480px) {

}

/* Animation */
@keyframes blink {
	0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
@keyframes border-blink {
	0% {
        border-color: #131313;
    }
    50% {
        border-color: #dbb56e;
    }
    100% {
        border-color: #131313;
    }
}

@media all {
  .page-break {
	display: none;
  }
}

@media print {
  html, body {
	height: initial !important;
	overflow: initial !important;
	-webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
	margin-top: 0.8rem;
	display: block;
	page-break-before: auto;
  }
}
